<template>
  <q-form ref="editForm">
    <c-card title="추가정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-danger-area
            :editable="editable"
            :disabled="true"
            label="위험물 저장소"
            name="chmDangerMstId"
            v-model="check.chmDangerMstId"
          ></c-danger-area>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :disabled="true"
            :data="check"
            label="안전관리자"
            type="user"
            name="chmDangerManageUserId"
            v-model="check.chmDangerManageUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :disabled="true"
            :data="check"
            label="담당자"
            type="user"
            name="chmDangerUserId"
            v-model="check.chmDangerUserId">
          </c-multi-field>
        </div>
      </template>
    </c-card>
    <c-table
      ref="tableType"
      title="저장품목"
      :columns="gridType.columns"
      :gridHeight="itemHeight"
      :data="check.checkitems"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="저장품목을 추가하세요."
      :hideBottom="true"
      rowKey="chmDangerRegularCheckItemId"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'calcuMethod'">
          {{props.row['calcuOriginal'] * props.row['calcuMultiple'] | toThousandFilter}}
        </template>
      </template>
    </c-table>
  </q-form>
</template>

<script>
export default {
  name: 'dangerRegularCheckEtc',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerRegularCheckId: '',
      }),
    },
    check: {
      type: Object,
      default: () => ({
        chmDangerRegularCheckId: '',
        chmDangerMstId: '',
        chmDangerMstTypeId: '',
        plantCd: '',
        chmDangerTypeCd: null,
        chmDangerLocation: '',
        chmDangerCheckName: '',
        chmDangerPermitNo: '',
        chmDangerUserId: '',
        chmDangerManageUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerInspectorUserId: '',
        chmStatusDangerRegularCheckCd: '',
        chmDangerCount: '',
        installPermitDate: '',
        chmDangerHandlingOverview: '',
        chmDangerStartDate: '',
        chmDangerEndDate: '',
        period: [],
        checkResults: [],
        deleteCheckResults: [],
        types: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridType: {
        columns: [
          {
            name: 'dangerItemName',
            field: 'dangerItemName',
            label: '품목',
            style: 'width:300px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'chmDangerTypeName',
            field: 'chmDangerTypeName',
            label: '품목 명',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'calcuOriginal',
            field: 'calcuOriginal',
            label: '품목별 지정수량',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'calcuMultiple',
            field: 'calcuMultiple',
            label: '배수(사업장 허가)',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'calcuMethod',
            field: 'calcuMethod',
            label: '적용 지정수량',
            style: 'width:150px',
            type: 'custom',
            sortable: false,
          },
        ],
        height: '300px',
        data: [],
      },
      editable: true,
    };
  },
  computed: {
    itemHeight() {
      let height = this.contentHeight - 350;
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
